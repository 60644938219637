export default {
  Edit: 'Edit',
  SavetoReload: 'and save to reload',
  Name: 'Name',
  events: {
    heading: 'Events/ Meetings',
    UpcomingEvents: 'Upcoming Events',
    register: 'Register here',
    documents: 'Documents',
    speeches: 'Speeches',
    files: 'Files',
    linktoregister: 'Link to registration'
  },
  footer: {
    events: 'Events/ Meetings',
    news: 'News',
    articles: 'Articles',
    faq: 'FAQ',
    alerts: 'Alerts'
  },
  login: {
    heading: 'Login',
    email: 'Email Address',
    password: 'Password',
    forgot: 'Forgot Password?',
    new: 'New User',
    data: 'Please enter your details to sign in',
    button: 'LOGIN'
  },
  hamburger: {
    hlag: 'HLAG Users',
    globparNet: 'Global Parliamentary Network',
    intLeg: 'International Legislations (SHERLOC)',
    unodc: 'UNODC & UNOCT',
    ipu: 'Inter Parliamentary Union'
  },
  logout: 'Logout',
  register: {
    heading: 'Register',
    haveAccount: 'Already have an account?',
    login: 'LOGIN',
    data: 'Please enter your details to sign up'
  },
  forgot: {
    heading: 'Forgot Password',
    content: 'Enter your email address below and we will send you instructions on how to change your password.',
    link: 'GO TO LOGIN'
  },
  submit: 'SUBMIT',
  common: {
    save: 'Save',
    fieldRequiredMessage: 'This field is required',
    invalidEmail: 'Invalid email',
    loading: 'Loading',
    emailValidationMessage: 'Enter a valid mail',
    passwordMatch: 'Passwords must match',
    submit: 'SUBMIT',
    passwordValidation: 'Password must have at least an uppercase character, a lowercase character, a digit, a special character and should be at least 8 characters long',
    subscribe: 'Subscribe',
    cancel: 'Cancel',
    update: 'Update',
    emailError: 'Primary email and secondary email should be different',
    nameValidation: 'Please enter a valid name',
    passwordLength: 'Password must be at least 8 characters long'
  },
  1000: 'Your session is expired, Please login to continue.',
  1001: 'Primary email is not valid.',
  1002: 'Secondary email is not valid.',
  1003: 'The passwords does not match.',
  1004: 'The password must have a minimum length of 8 characters.',
  1005: 'The password must contain atleast one special character.',
  1006: 'The password must contain atleast one uppercase letter.',
  1007: 'The password must contain atleast one lowercase letter.',
  1008: 'The password must contain atleast one digit.',
  1009: 'The IPU membership category is not valid.',
  1010: 'Avatar image could not be processed.',
  1011: 'Could not read the image file.',
  1012: 'Already registered email.',
  1013: 'Primary email cannot be the secondary email of existing user.',
  1014: 'Secondary email has already been used.',
  1015: 'User data updated successfully.',
  1016: 'User does not exist.',
  1017: 'We have emailed your password reset link!.',
  1018: 'Could not sent the password reset mail.',
  1019: 'Personal email has already been registered.',
  1020: 'Password updated successfully. You will be logged out shortly.',
  1021: 'Old password is incorrect.',
  1022: 'Invalid User.',
  1023: 'The reset password link has expired!.',
  1024: 'Password updated successfully.',
  1025: 'Invalid reset password link.',
  1026: 'Success',
  1027: 'Deleted the user account.',
  1028: 'Your account is not active.',
  1033: 'The user has not been activated or is blocked.',
  1034: 'Email or Password is incorrect.',
  1035: 'Too many failed login attempts from your IP address. This IP address is temporarily blocked.',
  1036: 'This route can only be accessed by anonymous users.',
  1037: 'Registration successful. Please wait for admin approval.',
  1038: 'Password updated.You will be logged out shortly.',
  1039: 'IPU is currently under maintenance. We will be back shortly. Thank you for your patience.',
  Upload: 'Upload Profile Photo',
  Select: 'Select',
  Email: 'Personal Email ID',
  SecondaryEmail: 'Secondary Email (Optional)',
  Copyright: 'Copyright IPU',
  IPUMembershipCategory: 'IPU Membership Category',
  ConfirmPassword: 'Confirm Password',
  Login: 'LOGIN',
  Register: 'REGISTER',
  Profile: 'Profile',
  Logout: 'Logout',
  ProfilePhoto: 'Profile Photo',
  Secondarymail: 'Secondary Email ID',
  Password: 'Password',
  ChangePassword: 'Change Password',
  upload: {
    upload: 'Upload Photo',
    change: 'Change Profile Picture',
    remove: 'Remove',
    sizeError: 'Pictures uploaded should be less than 2MB'
  },
  reset: {
    heading: 'Reset Password'
  },
  profile: {
    heading: 'Profile',
    fullName: 'Full Name',
    edit: 'Edit Profile',
    delete: 'Delete Account',
    currentPass: 'Current Password',
    newPass: 'New Password',
    confPass: 'Confirm New Password',
    Name: 'Name'
  },
  delete: {
    title: 'Delete Account?',
    content: 'Are you sure you want to delete this account?',
    delete: 'Delete',
    cancel: 'Cancel',
    picContent: 'Are you sure you want to remove this picture?',
    picTitle: 'Remove Picture?',
    remove: 'Remove'
  },
  apologies: 'Sorry. We couldn’t find the page you were looking for.',
  goHome: 'GO TO HOME',
  goLogin: 'GO TO LOGIN',
  Enterkeyword: 'Enter keyword to search...',
  SelectDate: 'Select Date',
  faq: {
    header: 'FAQ',
    noresult: 'No results found!'
  },
  news: {
    heading: 'News',
    UpcomingEvents: 'Now Trending'
  },
  articles: {
    heading: 'Articles',
    latest: 'Latest Articles'
  },
  alerts: {
    heading: 'Alerts',
    UpcomingEvents: 'Alerts older than 2 weeks will be removed automatically'
  },
  eventTypes: {
    message: 'Message',
    event: 'Event',
    news: 'News',
    article: 'Articles',
    event_meeting: 'Events/ Meetings'
  },
  message: {
    noData: 'No data available'
  },
  app: {
    reload: 'RELOAD',
    noNetMsg: 'We cannot detect any internet connectivity. Please check your internet connection and try again..',
    noNet: 'No Internet Connection!',
    update: 'A new update is available!',
    retry: 'RETRY',
    wrong: 'Something went wrong!',
    someWrong: 'Something went wrong. You can either retry now or try again later.'
  },
  hlag: {
    composition: 'Composition of the HLAG:',
    members: 'Members',
    terms: 'Terms of reference:',
    meeting: 'Meetings/ reports:',
    other: 'Other Information:',
    group: 'Position in group',
    parliament: 'Position in parliament',
    geoGroup: 'Geopolitical group',
    sizeError: 'Files uploaded should be less than 2MB',
    Resume: 'Resume',
    Upload: 'Upload Resume'
  },
  individualHLAG: {
    PositionInGroup: 'Position in group',
    PositionInParliament: 'Position in Parliament',
    GeopoliticalGroup: 'Geopolitical Group',
    EmailID: 'Email ID'
  }
}
